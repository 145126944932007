.container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.labelCenter {
  display: flex;
  align-items: center;
  height: 100%;
}
.yAxisLabel {
  width: 100%;
  padding-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 10px;
  color: #636d7e;
  font-weight: 400;
}

.barLabel {
  font-size: 10px;
  fill: #636d7e;
  font-weight: 400;
}

.bar {
  fill: #5ca9e9;
}

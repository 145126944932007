.container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.container svg {
  overflow: visible;
}

.xAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', Roboto, sans-serif;
  font-size: 10px;
  text-anchor: middle;
}

.xAxis text {
  fill: currentColor;
}

.yAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', Roboto, sans-serif;
  font-size: 10px;
  text-anchor: middle;
}

.yAxis text {
  fill: currentColor;
}

.axisGridLine {
  stroke: #e6e8ed;
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.line {
  stroke-width: 2;
  fill: none;
}

.tooltipTitle {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font-size: 10px;
  color: #d7dbe3;
  font-weight: 400;
}

.tooltipLabel {
  display: flex;
  align-items: center;
}
.tooltipColor {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 30%;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem;
  height: 100vh;
  background-color: var(--chakra-colors-background-primary);
  color: var(--chakra-colors-text-primary);
}

.pageTitle {
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 32px;
}

/* .chartGrid {
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 1rem 0;
  width: 100%;
  height: 100%;

  gap: 1rem;
}

.chartGridRow {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.chartGridRowItem {
  width: 25%;
  height: 100%;
} */

.gridContainer {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 160px);
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
}

.shadowGrid {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 160px);
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
}

.shadowGridSlot {
  position: relative;
  background: transparent;
}

.gridSlot {
  border-radius: 0.375rem;
  transition: background 0.4s ease;
  min-width: 0;
}
.gridSlot.showAllSlots {
  background-color: #0001;
}

.gridSlot:hover {
  cursor: pointer;
  background-color: #0001;
}

.gridSlot[data-swapy-highlighted] {
  background: #0003;
}

.gridSlot[data-swapy-highlighted] .gridItem {
  box-shadow: 5px 5px 10px #0007;
}

.gridItem {
  width: 100%;
  height: 100%;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.gridItem[data-swapy-dragging] {
  opacity: 0.6;
  box-shadow: 10px 5px 15px #0003;
}

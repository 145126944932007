/* E/W/N/S */
.resizer {
  pointer-events: all;
  position: absolute;
  background: transparent;
  transition: background 0.1s ease;
}

.resizer.dragging {
  background: #46bef1;
}

.resizer:hover {
  background: #46bef1;
  transition: background 0.2s ease;
}

.resizeE {
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  cursor: e-resize;
}

.resizeW {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  cursor: w-resize;
}

.resizeN {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  cursor: n-resize;
}

.resizeS {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  cursor: s-resize;
}

/* NE/NW/SE/SW */
.resizerCorner {
  pointer-events: all;
  position: absolute;
  width: 16px;
  height: 16px;
  border-color: transparent;
  border-style: solid;
  transition: border-color 0.1s ease;
}

.resizerCorner.dragging {
  border-color: #46bef1;
}

.resizerCorner:hover {
  border-color: #46bef1;
  transition: border-color 0.2s ease;
}

.resizeNE {
  top: 0;
  right: 0;
  border-top-width: 6px;
  border-right-width: 6px;
  border-top-right-radius: 0.375rem;
  cursor: ne-resize;
}

.resizeNW {
  top: 0;
  left: 0;
  border-top-width: 6px;
  border-left-width: 6px;
  border-top-left-radius: 0.375rem;
  cursor: nw-resize;
}

.resizeSE {
  bottom: 0;
  right: 0;
  border-bottom-width: 6px;
  border-right-width: 6px;
  border-bottom-right-radius: 0.375rem;
  cursor: se-resize;
}

.resizeSW {
  bottom: 0;
  left: 0;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-bottom-left-radius: 0.375rem;
  cursor: sw-resize;
}

.selector {
  margin-bottom: 1rem;
}

.selectorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.clearButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* .filterBar {
  display: flex;
  align-items: center;
  margin-top: 17.5px;
} */

.primaryFilterChipsContainer {
  display: flex;
  flex-direction: row;
}

.bookmarkList {
  padding: 0.5rem 0 0;
  max-height: 200px;
  overflow-y: auto;
}

.bookmarkItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-radius: 4px;
  height: 32px;
}

.bookmarkItemName {
  flex: 1;
  height: 100%;
  padding: 4px;
  border-radius: 4px;
  line-height: 24px;
}

.bookmarkItemName:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.bookmarkItem:hover .bookmarkItemSaveButton,
.bookmarkItem:hover .bookmarkItemDeleteButton {
  display: inline-flex;
}

.noBookmarks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  font-size: 12px;
  padding: 4px;
  color: #808080;
  border: 1px solid #e2e8f0;
}
